import ImportalPieChart from '@/components/reporting/ImportalPieChart';
import ImportalAutocomplete from '@/shared-components/ImportalAutocomplete/ImportalAutocomplete';
import ImportalFilter from '@/shared-components/ImportalFilter/ImportalFilter';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalReportCard from '@/shared-components/ImportalReportCard/ImportalReportCard';
import ImportalSearch from '@/shared-components/ImportalSearch/ImportalSearch';
import { Add } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { styled } from '@mui/system';
import { ColumnConfig, ImportalTableDataType, TableConfig } from 'common/interfaces/importalTable';
import { ChartConfig, ChartUnitType, LegendPositionType, ReportChartType } from 'common/interfaces/reports';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const StyledChip = styled(Chip)(() => ({
  backgroundColor: '#77A8F6',
  color: 'white',
  borderRadius: '10px!important',
  fontWeight: '700',
  padding: '8px',
  fontSize: '12px',
  '& .MuiChip-deleteIcon': {
    color: 'white',
    fontSize: '18px',
  },
}));

export default function BrokerSummaryPage() {
  // ----------------------------------------------------
  //  Original Data and Chart Config (unchanged from user)
  // ----------------------------------------------------

  // 1) Ocean On-Time Data & Config (NO percentage)
  const [oceanOnTimeData] = useState<any[]>([
    { status: 'On-Time', value: 89, color: '#4caf50' },
    { status: 'Delayed', value: 11, color: '#f44336' },
  ]);

  const oceanOnTimeChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'On-time Rate for Ocean',
    chartDescriptor: 'Shows the on-time vs delayed shipments for ocean transport.',
    pieConfig: {
      seriesNameKey: 'status',
      seriesValueKey: 'value',
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.RIGHT,
  };

  // 2) Air On-Time Data & Config (NO percentage)
  const [airOnTimeData] = useState<any[]>([
    { status: 'On-Time', value: 92, color: '#4caf50' },
    { status: 'Delayed', value: 8, color: '#f44336' },
  ]);

  const airOnTimeChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'On-time Rate for Air',
    chartDescriptor: 'Shows the on-time vs delayed shipments for air transport.',
    pieConfig: {
      seriesNameKey: 'status',
      seriesValueKey: 'value',
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.RIGHT,
  };

  // 3) Truck On-Time Data & Config (NO percentage)
  const [truckOnTimeData] = useState<any[]>([
    { status: 'On-Time', value: 94, color: '#4caf50' },
    { status: 'Delayed', value: 6, color: '#f44336' },
  ]);

  const truckOnTimeChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'On-time Rate for Truck',
    chartDescriptor: 'Shows the on-time vs delayed shipments for trucking services.',
    pieConfig: {
      seriesNameKey: 'status',
      seriesValueKey: 'value',
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.RIGHT,
  };

  // 4) Rail On-Time Data & Config (NO percentage)
  const [railOnTimeData] = useState<any[]>([
    { status: 'On-Time', value: 94, color: '#4caf50' },
    { status: 'Delayed', value: 6, color: '#f44336' },
  ]);

  const railOnTimeChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'On-time Rate for Rail',
    chartDescriptor: 'Shows the on-time vs delayed shipments for rail transport.',
    pieConfig: {
      seriesNameKey: 'status',
      seriesValueKey: 'value',
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.RIGHT,
  };

  // 5) Document Collection Data & Config (NO percentage)
  const [documentCollectionData] = useState<any[]>([
    { status: 'Documents Collected', value: 90, color: '#2196f3' },
    { status: 'Missing Documents', value: 10, color: '#f44336' },
  ]);

  const documentCollectionChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'Document Collection',
    chartDescriptor: 'Percentage of shipments with complete documentation.',
    pieConfig: {
      seriesNameKey: 'status',
      seriesValueKey: 'value',
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.RIGHT,
  };

  // ----------------------------------------------------------
  //  Additional 4 Pie Charts that reuse the same data but show
  //  values as percentages (ChartUnitType.Percentage)
  // ----------------------------------------------------------

  // Reuse oceanOnTimeData but show as percentage
  const oceanOnTimePercentageChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'Ocean On-time (as %)',
    chartDescriptor: 'Same Ocean data shown with percentages.',
    pieConfig: {
      seriesNameKey: 'status',
      seriesValueKey: 'value',
      seriesValueUnits: ChartUnitType.Percentage, // <--- Mark as percentage
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.RIGHT,
  };

  // Reuse airOnTimeData but show as percentage
  const airOnTimePercentageChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'Air On-time (as %)',
    chartDescriptor: 'Same Air data shown with percentages.',
    pieConfig: {
      seriesNameKey: 'status',
      seriesValueKey: 'value',
      seriesValueUnits: ChartUnitType.Percentage,
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.RIGHT,
  };

  // Reuse truckOnTimeData but show as percentage
  const truckOnTimePercentageChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'Truck On-time (as %)',
    chartDescriptor: 'Same Truck data shown with percentages.',
    pieConfig: {
      seriesNameKey: 'status',
      seriesValueKey: 'value',
      seriesValueUnits: ChartUnitType.Percentage,
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.RIGHT,
  };

  // Reuse railOnTimeData but show as percentage
  const railOnTimePercentageChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'Rail On-time (as %)',
    chartDescriptor: 'Same Rail data shown with percentages.',
    pieConfig: {
      seriesNameKey: 'status',
      seriesValueKey: 'value',
      seriesValueUnits: ChartUnitType.Percentage,
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.RIGHT,
  };

  // -------------------------------------------------
  //  Columns, TableConfig, and Filtering Setup (same)
  // -------------------------------------------------
  const [columns] = useState<ColumnConfig[]>([
    {
      name: 'transportType',
      displayName: 'Transport Type',
      isVisible: true,
      sortable: true,
      filterable: true,
      dataType: 'string',
    },
    {
      name: 'date',
      displayName: 'Date',
      isVisible: true,
      sortable: true,
      filterable: true,
      dataType: ImportalTableDataType.DATE_STRING,
    },
  ]);

  const [tableConfig, setTableConfig] = useState<TableConfig>({
    columns: columns,
    filterConfig: {},
    sortConfig: [],
    subKeyForData: '',
  });

  const [selectedFilteringColumn, setSelectedFilteringColumn] = useState<ColumnConfig | null>(null);
  const [selectedFilteringValues, setSelectedFilteringValues] = useState<any[]>([]);

  const handleAddFilterChip = (columnName: string, displayName: string, values: Array<any>) => {
    const newFilterConfig = {
      ...tableConfig.filterConfig,
      [columnName]: {
        allowedValues: values,
        columnName: columnName,
        displayName: displayName,
      },
    };
    setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
  };

  const handleDeleteFilterChip = (columnName: string, value: string) => {
    const newFilterConfig = { ...tableConfig.filterConfig };

    if (value === '') {
      delete newFilterConfig[columnName];
    } else {
      const filteredValues = newFilterConfig[columnName].allowedValues.filter((val: any) => val !== value);
      if (filteredValues.length === 0) {
        delete newFilterConfig[columnName];
      } else {
        newFilterConfig[columnName] = {
          ...newFilterConfig[columnName],
          allowedValues: filteredValues,
        };
      }
    }
    setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
  };

  // -----------------------------------------------
  //  Filtered Data State for all charts
  // -----------------------------------------------
  const [filteredData, setFilteredData] = useState<{
    ocean: any[];
    air: any[];
    truck: any[];
    rail: any[];
    documents: any[];
  }>({
    ocean: oceanOnTimeData,
    air: airOnTimeData,
    truck: truckOnTimeData,
    rail: railOnTimeData,
    documents: documentCollectionData,
  });

  // If you want the new charts also filterable with the same logic,
  // you can keep the same data references.

  // -----------------------------------------------
  //  Search State (optional)
  // -----------------------------------------------
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    // Implement any search logic if needed
  };

  // -----------------------------------------------
  //  Apply Filters (Example Implementation)
  // -----------------------------------------------
  useEffect(() => {
    const getFilteredData = (dataArray: any[]): any[] => {
      return dataArray.filter((item) => {
        return Object.keys(tableConfig.filterConfig).every((columnName) => {
          const filter = tableConfig.filterConfig[columnName];
          if (!filter || filter.allowedValues.length === 0) return true;

          const itemValue = item[columnName];
          const columnConfig = columns.find((col) => col.name === columnName);
          if (!columnConfig) return true;

          if (filter.allowedValues.length === 2 && columnConfig.dataType === ImportalTableDataType.DATE_STRING) {
            const [startDate, endDate] = filter.allowedValues;
            const itemDate = moment(itemValue);
            return itemDate.isSameOrAfter(moment(startDate), 'day') && itemDate.isSameOrBefore(moment(endDate), 'day');
          }
          return filter.allowedValues.includes(itemValue);
        });
      });
    };

    setFilteredData({
      ocean: getFilteredData(oceanOnTimeData),
      air: getFilteredData(airOnTimeData),
      truck: getFilteredData(truckOnTimeData),
      rail: getFilteredData(railOnTimeData),
      documents: getFilteredData(documentCollectionData),
    });
  }, [tableConfig, oceanOnTimeData, airOnTimeData, truckOnTimeData, railOnTimeData, documentCollectionData, columns]);

  // -----------------------------------------------
  //  Menu Options for Report Cards
  // -----------------------------------------------
  const menuOptions = [
    { label: 'Edit', onClick: () => console.log('Edit clicked') },
    { label: 'Delete', onClick: () => console.log('Delete clicked') },
    { label: 'View Details', onClick: () => console.log('View Details clicked') },
  ];

  // -----------------------------------------------
  //  Render Filter Chips
  // -----------------------------------------------
  const renderFilterChips = () => {
    return Object.keys(tableConfig.filterConfig).map((columnName) => {
      const filterConfig = tableConfig.filterConfig[columnName];
      const columnObj = columns.find((col) => col.name === columnName);
      const isDateColumn = columnObj?.dataType === ImportalTableDataType.DATE_STRING;

      if (isDateColumn && filterConfig.allowedValues.length === 2) {
        const [startDate, endDate] = filterConfig.allowedValues;
        return (
          <StyledChip
            key={`${columnName}-date-range`}
            label={`${filterConfig.displayName || columnName}: 
              ${moment(startDate).format('MM/DD/YYYY')} - 
              ${moment(endDate).format('MM/DD/YYYY')}`}
            onDelete={() => handleDeleteFilterChip(columnName, '')}
          />
        );
      }
      return filterConfig.allowedValues.map((value: any) => (
        <StyledChip
          key={`${columnName}-${value}`}
          label={`${filterConfig.displayName || columnName}: ${value}`}
          onDelete={() => handleDeleteFilterChip(columnName, value)}
        />
      ));
    });
  };

  // -----------------------------------------------
  //  For demonstration, combining all data
  // -----------------------------------------------
  const sortedAndFilteredData = [
    ...filteredData.ocean.map((item) => ({ ...item, source: 'oceanOnTimeData' })),
    ...filteredData.air.map((item) => ({ ...item, source: 'airOnTimeData' })),
    ...filteredData.truck.map((item) => ({ ...item, source: 'truckOnTimeData' })),
    ...filteredData.rail.map((item) => ({ ...item, source: 'railOnTimeData' })),
    ...filteredData.documents.map((item) => ({ ...item, source: 'documentCollectionData' })),
  ];

  // -----------------------------------------------
  //  Render the Page
  // -----------------------------------------------
  return (
    <>
      {/* ----- Top Section: Autocomplete, Filter, Filter Chips, and Action Button ----- */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        {/* ----- LEFT AREA: Autocomplete & Filter Controls ----- */}
        <div style={{ flex: 1, display: 'flex', gap: '6px', alignItems: 'end' }}>
          <ImportalAutocomplete
            options={columns}
            value={selectedFilteringColumn}
            onChange={(event, value) => {
              setSelectedFilteringColumn(value as ColumnConfig | null);
              setSelectedFilteringValues([]);
            }}
            placeholder="Filter by column..."
          />

          <ImportalFilter
            selectedFilteringColumn={selectedFilteringColumn}
            selectedFilteringValues={selectedFilteringValues}
            setSelectedFilteringColumn={setSelectedFilteringColumn}
            setSelectedFilteringValues={setSelectedFilteringValues}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            sortedAndFilteredData={sortedAndFilteredData}
            handleAddFilterChip={handleAddFilterChip}
          />

          {/* Display Filter Chips */}
          <div style={{ overflowY: 'auto', alignSelf: 'center', maxHeight: '40px' }}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'end',
                columnGap: '4px',
                rowGap: '2px',
              }}
            >
              {renderFilterChips()}
            </div>
          </div>
        </div>

        {/* ----- RIGHT AREA: Search and Create New Report Button ----- */}
        <div style={{ display: 'flex', alignItems: 'end', gap: '8px' }}>
          <ImportalSearch value={searchQuery} onChange={handleSearchChange} placeholder="Search..." />
          <ImportalPrimaryButton
            style={{ width: '200px', borderRadius: '10px', fontSize: '14px' }}
            onClick={() => console.log('Create New Report')}
            text="Create New Report"
            endIcon={<Add />}
          />
        </div>
      </div>

      {/* ----- Original 5 Pie Charts (unchanged) ----- */}
      <div style={{ display: 'flex', width: '100%', gap: '24px', marginBottom: '24px', flexWrap: 'wrap' }}>
        <div style={{ flex: 1, minWidth: '300px' }}>
          <ImportalReportCard title="Ocean On-time Rate" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={oceanOnTimeChartConfig} data={filteredData.ocean} />
          </ImportalReportCard>
        </div>

        <div style={{ flex: 1, minWidth: '300px' }}>
          <ImportalReportCard title="Air On-time Rate" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={airOnTimeChartConfig} data={filteredData.air} />
          </ImportalReportCard>
        </div>
      </div>

      <div style={{ display: 'flex', width: '100%', gap: '24px', marginBottom: '24px', flexWrap: 'wrap' }}>
        <div style={{ flex: 1, minWidth: '300px' }}>
          <ImportalReportCard title="Truck On-time Rate" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={truckOnTimeChartConfig} data={filteredData.truck} />
          </ImportalReportCard>
        </div>

        <div style={{ flex: 1, minWidth: '300px' }}>
          <ImportalReportCard title="Rail On-time Rate" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={railOnTimeChartConfig} data={filteredData.rail} />
          </ImportalReportCard>
        </div>
      </div>

      <div style={{ display: 'flex', width: '100%', gap: '24px', marginBottom: '24px', flexWrap: 'wrap' }}>
        <div style={{ flex: 1, minWidth: '300px' }}>
          <ImportalReportCard title="Document Collection" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={documentCollectionChartConfig} data={filteredData.documents} />
          </ImportalReportCard>
        </div>
      </div>

      {/* ------------------------------------------------- */}
      {/* 4 NEW Pie Charts reusing the same data as above,  */}
      {/* but rendered as percentages (ChartUnitType.Percentage) */}
      {/* ------------------------------------------------- */}
      <div style={{ display: 'flex', width: '100%', gap: '24px', marginBottom: '24px', flexWrap: 'wrap' }}>
        {/* Ocean (Percentage) */}
        <div style={{ flex: 1, minWidth: '300px' }}>
          <ImportalReportCard title="Ocean On-time Rate (%)" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={oceanOnTimePercentageChartConfig} data={filteredData.ocean} />
          </ImportalReportCard>
        </div>

        {/* Air (Percentage) */}
        <div style={{ flex: 1, minWidth: '300px' }}>
          <ImportalReportCard title="Air On-time Rate (%)" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={airOnTimePercentageChartConfig} data={filteredData.air} />
          </ImportalReportCard>
        </div>
      </div>

      <div style={{ display: 'flex', width: '100%', gap: '24px', marginBottom: '24px', flexWrap: 'wrap' }}>
        {/* Truck (Percentage) */}
        <div style={{ flex: 1, minWidth: '300px' }}>
          <ImportalReportCard title="Truck On-time Rate (%)" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={truckOnTimePercentageChartConfig} data={filteredData.truck} />
          </ImportalReportCard>
        </div>

        {/* Rail (Percentage) */}
        <div style={{ flex: 1, minWidth: '300px' }}>
          <ImportalReportCard title="Rail On-time Rate (%)" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={railOnTimePercentageChartConfig} data={filteredData.rail} />
          </ImportalReportCard>
        </div>
      </div>
    </>
  );
}
