import { ChartUnitType } from 'common/interfaces/reports';
import * as d3 from 'd3';
import { useState } from 'react';
import { BarTooltip } from './BarTooltip';

/**
 * Helper to generate a path for a rectangle with only the top corners rounded.
 *
 * @param x - The x position of the bar.
 * @param y - The y position (top) of the bar.
 * @param width - The width of the bar.
 * @param height - The height of the bar.
 * @param radius - The radius for the top corners.
 */
function getRoundedTopRectPath(x: number, y: number, width: number, height: number, radius: number): string {
  // Clamp the radius to at most half the width
  const r = Math.min(radius, width / 2);
  return `
    M ${x} ${y + r}
    Q ${x} ${y} ${x + r} ${y}
    H ${x + width - r}
    Q ${x + width} ${y} ${x + width} ${y + r}
    V ${y + height}
    H ${x}
    Z
  `;
}

interface ImportalVerticalBarsProps {
  data: any[];
  xScale: d3.ScaleBand<string>;
  yScale: d3.ScaleLinear<number, number>;
  seriesNameKey: string;
  seriesValueKey: string;
  barColor?: string;
  /** Enable rounded top corners */
  rounded?: boolean;
  unit: ChartUnitType;
}

export function ImportalVerticalBars({
  data,
  xScale,
  yScale,
  seriesNameKey,
  seriesValueKey,
  barColor = '#FFC107',
  rounded = false,
  unit,
}: ImportalVerticalBarsProps) {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <g>
      {data.map((d: any, i: number) => {
        const category = d[seriesNameKey];
        const value = +d[seriesValueKey];

        // Position and dimensions for the bar
        const barX = xScale(category) ?? 0;
        const barY = yScale(d[1] ?? value);
        const barHeight = yScale(d[0] ?? 0) - yScale(d[1] ?? value);

        return (
          <g key={i} onMouseEnter={() => setHoveredIndex(i)} onMouseLeave={() => setHoveredIndex(null)}>
            {rounded ? (
              <path
                d={getRoundedTopRectPath(
                  barX,
                  barY,
                  xScale.bandwidth(),
                  barHeight,
                  8 // corner radius
                )}
                fill={barColor}
              />
            ) : (
              <rect x={barX} y={barY} width={xScale.bandwidth()} height={barHeight} fill={barColor} />
            )}

            {/* Tooltip on hover */}
            {hoveredIndex === i && (
              <BarTooltip
                // Center at top of the bar
                x={barX + xScale.bandwidth() / 2}
                y={barY - 8}
                value={value}
                unit={unit}
              />
            )}
          </g>
        );
      })}
    </g>
  );
}
