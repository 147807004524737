import React, { useRef, useState, useLayoutEffect } from 'react';
import { renderChartLabel } from '@/utils/reportingUtils';
import { ChartUnitType } from 'common/interfaces/reports';

interface BarTooltipProps {
  x: number;
  y: number;
  value: number;
  unit: ChartUnitType;
}

/**
 * A self-contained tooltip component that:
 * 1. Measures the text content via getBBox().
 * 2. Dynamically adjusts the tooltip rectangle size so text is never cut off.
 */
export function BarTooltip({ x, y, value, unit }: BarTooltipProps) {
  const textRef = useRef<SVGTextElement>(null);
  const [textWidth, setTextWidth] = useState(0);

  // Prepare the label text
  const label = renderChartLabel(value, unit);

  useLayoutEffect(() => {
    if (textRef.current) {
      // Measure the <text> element to get its width
      const bbox = textRef.current.getBBox();
      setTextWidth(bbox.width);
    }
  }, [label]);

  // We'll add some horizontal padding so the text isn't touching the edges
  const paddingX = 8;
  const tooltipWidth = textWidth + paddingX * 2;
  const tooltipHeight = 25; // Enough for single-line text w/ some vertical padding

  return (
    <g transform={`translate(${x}, ${y})`} style={{ pointerEvents: 'none' }}>
      {/* 
        Render text once, in place, but hidden until we measure.
        The textAnchor="middle" means the text is centered around x=0.
      */}
      <text ref={textRef} x={0} y={-8} fontSize="12px" fill="white" textAnchor="middle" style={{ opacity: 0 }}>
        {label}
      </text>

      {/* The background rect, placed around the measured text width */}
      <rect x={-tooltipWidth / 2} y={-tooltipHeight} width={tooltipWidth} height={tooltipHeight} rx={4} fill="#666" />

      {/* Now render the text again, visible */}
      <text x={0} y={-8} fontSize="12px" fill="white" textAnchor="middle">
        {label}
      </text>
    </g>
  );
}
