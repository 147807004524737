import { useChartDimensions } from '@/custom-hooks/reporting/useChartDimensions';
import { BarChartOrientation, ChartConfig, ChartUnitType } from 'common/interfaces/reports';
import * as d3 from 'd3';
import { useMemo } from 'react';
import { CategoricalXAxis } from './CategoricalXAxis';
import { CategoricalYAxis } from './CategoricalYAxis';
import { ImportalHorizontalBars } from './ImportalHorizontalBars';
import { ImportalVerticalBars } from './ImportalVerticalBars';
import { NumericXAxis } from './NumericXAxis';
import { NumericYAxis } from './NumericYAxis';

interface Props {
  chartConfig: ChartConfig;
  data: any[];
}

export default function ImportalBarChart({ chartConfig, data }: Props) {
  const [ref, dms] = useChartDimensions({
    // width: 700,
    // height: 600,
    marginLeft: 100,
    marginRight: 40,
    marginTop: 20,
    marginBottom: 60,
  });

  const { seriesNameKey, seriesValueKey, xAxisName, yAxisName } = chartConfig.barConfig ?? {};

  // Collect all category labels
  const categories = useMemo(() => {
    if (!seriesNameKey) return [];
    return data.map((d) => d[seriesNameKey]);
  }, [data, seriesNameKey]);

  // Max numeric value for the domain
  const maxValue = useMemo(() => {
    if (!data || !seriesValueKey) return 0;
    return d3.max(data, (d) => +d[seriesValueKey]) ?? 0;
  }, [data, seriesValueKey]);

  // X scale (categorical if vertical, numeric if horizontal)
  const xScale = useMemo(() => {
    if (!seriesNameKey || !seriesValueKey) return null;

    if (chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL) {
      return d3.scaleBand<string>().domain(categories).range([0, dms.boundedWidth]).padding(0.2);
    } else {
      return d3.scaleLinear().domain([0, maxValue]).range([0, dms.boundedWidth]).nice();
    }
  }, [chartConfig.barConfig?.orientation, categories, maxValue, dms.boundedWidth, seriesNameKey, seriesValueKey]);

  // Y scale (numeric if vertical, categorical if horizontal)
  const yScale = useMemo(() => {
    if (!seriesNameKey || !seriesValueKey) return null;

    if (chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL) {
      return d3.scaleLinear().domain([0, maxValue]).range([dms.boundedHeight, 0]).nice();
    } else {
      return d3.scaleBand<string>().domain(categories).range([0, dms.boundedHeight]).padding(0.2);
    }
  }, [chartConfig.barConfig?.orientation, categories, maxValue, dms.boundedHeight, seriesNameKey, seriesValueKey]);

  // For vertical bars => we want horizontal grid lines at the same positions as Y-axis ticks
  const yTicks = useMemo(() => {
    if (chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL && yScale && 'ticks' in yScale) {
      // e.g. 5 ticks for the Y axis
      return yScale.ticks(5);
    }
    return [];
  }, [yScale, chartConfig.barConfig?.orientation]);

  if (!seriesNameKey || !seriesValueKey) {
    return <div style={{ color: '#999' }}>Missing axis keys.</div>;
  }
  if (!xScale || !yScale) {
    return <div style={{ color: '#999' }}>Scales could not be computed.</div>;
  }

  return (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
      <figure style={{ margin: 0, width: dms.width, height: dms.height }}>
        <svg width={dms.width} height={dms.height}>
          {/* Chart area transform */}
          <g transform={`translate(${dms.marginLeft}, ${dms.marginTop})`}>
            {/* Background */}
            <rect width={dms.boundedWidth} height={dms.boundedHeight} fill="#fff" rx={8} />

            {/* GRID LINES (draw behind bars) */}
            <g className="chart-grid">
              {chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL &&
                yTicks.map((val, i) => {
                  const yPos = (yScale as d3.ScaleLinear<number, number>)(val);
                  return (
                    <line
                      key={i}
                      x1={0}
                      x2={dms.boundedWidth}
                      y1={yPos}
                      y2={yPos}
                      stroke="#E0E0E0"
                      strokeWidth={2}
                      strokeDasharray="4 4"
                    />
                  );
                })}
            </g>

            {/* BARS (drawn on top of grid lines) */}
            {chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL ? (
              <ImportalVerticalBars
                data={data}
                xScale={xScale}
                yScale={yScale}
                seriesNameKey={seriesNameKey}
                seriesValueKey={seriesValueKey}
                barColor={chartConfig.barConfig?.barColor}
                unit={chartConfig.barConfig?.yAxisUnits || ChartUnitType.Number}
                rounded={true}
              />
            ) : (
              <ImportalHorizontalBars
                data={data}
                xScale={xScale}
                yScale={yScale}
                seriesNameKey={seriesNameKey}
                seriesValueKey={seriesValueKey}
                barColor={chartConfig.barConfig?.barColor}
                unit={chartConfig.barConfig?.xAxisUnits || ChartUnitType.Number}
              />
            )}

            {/* Y Axis (numeric for vertical, categorical for horizontal) */}
            {chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL ? (
              <NumericYAxis
                axisLabel={yAxisName || ''}
                range={[0, maxValue]}
                height={dms.boundedHeight}
                /** Pass the same yTicks so the axis lines up perfectly */
                tickValues={yTicks}
              />
            ) : (
              <CategoricalYAxis
                axisLabel={yAxisName || ''}
                categories={categories}
                height={dms.boundedHeight}
                showAxisLine={chartConfig.showAxisLine}
              />
            )}
          </g>

          {/* X Axis - positioned below the chart */}
          <g transform={`translate(${dms.marginLeft}, ${dms.marginTop + dms.boundedHeight})`}>
            {chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL ? (
              <CategoricalXAxis axisLabel={xAxisName || ''} categories={categories} width={dms.boundedWidth} />
            ) : (
              <NumericXAxis
                axisLabel={xAxisName || ''}
                range={[0, maxValue]}
                width={dms.boundedWidth}
                showAxisLine={chartConfig.showAxisLine}
                showAxisTicks={chartConfig.showAxisTicks}
              />
            )}
          </g>
        </svg>
      </figure>
    </div>
  );
}
