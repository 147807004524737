import { useAPI } from '@/api/APIContext';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import CreateNewReport from '@/pages/reports/CreateNewReport';
import CustomReports from '@/pages/reports/CustomReports';
import GeneratedReports from '@/pages/reports/GeneratedReports';
import Summary from '@/pages/reports/Summary';
import ImportalPage from '@/shared-components/ImportalPage/ImportalPage';
import ImportalTabs from '@/shared-components/ImportalTabs/ImportalTabs';
import { Box } from '@mui/material';
import { Report } from 'common/interfaces/reports';
import { createContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ReportPage from './ReportPage';

export interface IReportsPageContext {
  reports: Report[];
  refreshReports: () => void;
}

export const ReportsPageContext = createContext<IReportsPageContext>({
  reports: [],
  refreshReports: () => {},
});

const { Provider } = ReportsPageContext;

export default function ReportsPage() {
  const api = useAPI();
  const [reports, setReports] = useState<Report[]>([]);
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  const refreshReports = () => {
    api
      .getReportsForUser()
      .then(({ data }) => {
        setReports(data);
      })
      .catch((err) => {
        console.error('error getting reports', err);
      });
  };

  const clickRoute = () => {};

  useEffect(() => {
    refreshReports();
  }, []);

  return (
    <Routes>
      <Route
        index
        element={
          <ImportalPage
            loadingStatus={loadingStatus}
            loadingText={loadingText}
            successText={successText}
            errorText={errorText}
            header={'Reporting & Analytics'}
            subheader="Generate any compliance data, reporting, or analytics at the click of a button"
          >
            <Box>
              <Provider value={{ reports, refreshReports }}>
                <ImportalTabs
                  tabsConfig={[
                    { title: 'Summary', element: <Summary /> },
                    { title: 'Generated Reports', element: <GeneratedReports /> },
                    { title: 'Custom Reports', element: <CustomReports /> },
                    { title: 'New Report', element: <CreateNewReport /> },
                  ]}
                />
              </Provider>
            </Box>
          </ImportalPage>
        }
      />
      {/* Dynamic route for a single report */}
      <Route path="/*" element={<ReportPage />} />
    </Routes>
  );
}
