import { useAPI } from '@/api/APIContext';
import ImportalBarChart from '@/components/reporting/ImportalBarChart';
import ImportalPieChart from '@/components/reporting/ImportalPieChart';
import ImportalStackedBarChart from '@/components/reporting/ImportalStackedBarChart';
import { useQuery } from '@/custom-hooks/UseQuery';
import ImportalAutocomplete from '@/shared-components/ImportalAutocomplete/ImportalAutocomplete';
import ImportalFilter from '@/shared-components/ImportalFilter/ImportalFilter';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalReportCard from '@/shared-components/ImportalReportCard/ImportalReportCard';
import ImportalSearch from '@/shared-components/ImportalSearch/ImportalSearch';
import { Add } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { styled } from '@mui/system';
import { defaultReportViewName } from 'common/constants/reportingConstants';
import { ColumnConfig, ImportalTableDataType, TableConfig } from 'common/interfaces/importalTable';
import { ReportChartType } from 'common/interfaces/reports';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportsPageContext } from './ReportsPage';

const StyledChip = styled(Chip)(() => ({
  backgroundColor: '#77A8F6',
  color: 'white',
  borderRadius: '10px!important',
  fontWeight: '700',
  padding: '8px',
  fontSize: '12px',
  '& .MuiChip-deleteIcon': {
    color: 'white',
    fontSize: '18px',
  },
}));

export default function Summary() {
  const api = useAPI();
  const navigate = useNavigate();
  const { reports } = useContext(ReportsPageContext);

  // // Example of deriving a report ID to view. Adjust as needed:
  const reportId = reports.length > 0 ? reports[0]._id : 'default-report-id';

  // ----- Fetch Data -----
  const fetchChartConfigs = useCallback(async () => {
    try {
      const response = await api.getReportsView(defaultReportViewName);
      const reports = response.data ?? [];

      const now = new Date();
      const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

      for (const report of reports) {
        if (new Date(report.lastRun) < twentyFourHoursAgo) {
          // TODO: UI friendly way of doing this.
          console.log(`Re-running report: ${report._id}`);
          await api.runReport(report._id.toString()); // Call runReport API
        }
      }
      return reports;
    } catch (error) {
      console.error('Error fetching reports:', error);
      return [];
    }
  }, [api]);

  const {
    data: reportData,
    isLoading,
    isError,
  } = useQuery({ queryKey: [], queryFn: fetchChartConfigs, initialData: [] });

  const fetchData = useCallback(async () => {
    try {
      const promises = reportData.map((report) => api.getReportData(report._id.toString()));
      const responses = await Promise.all(promises);
      const dataByReportName = reportData.reduce((acc, report, index) => {
        acc[report.name] = responses[index].data;
        return acc;
      }, {});

      return dataByReportName;
    } catch (error) {
      console.error('Error fetching summary metrics:', error);
      return {};
    }
  }, [reportData]);

  const {
    data: chartData,
    isLoading: isFetchDataLoading,
    isError: isFetchDataError,
  } = useQuery({ queryKey: [reportData], queryFn: fetchData, initialData: {} });

  const overallLandedCostsChartConfig = reportData.find((report) => report.name === 'Overall Customs Landed Cost')
    ?.reportConfig?.chartConfig ?? { chartType: ReportChartType.NO_CHART, chartName: 'No Chart' };
  const generalDutyChartConfig = reportData.find((report) => report.name === 'Duties and Fees Spend By Product')
    ?.reportConfig?.chartConfig ?? { chartType: ReportChartType.NO_CHART, chartName: 'No Chart' };
  const countryPieChartConfig = reportData.find((report) => report.name === 'Distribution of Products by Country')
    ?.reportConfig?.chartConfig ?? { chartType: ReportChartType.NO_CHART, chartName: 'No Chart' };
  const additionalFeesChartConfig = reportData.find((report) => report.name === 'Additional Fees')?.reportConfig
    ?.chartConfig ?? { chartType: ReportChartType.NO_CHART, chartName: 'No Chart' };
  const generalDutyByHtsnoChartConfig = reportData.find((report) => report.name === 'General Duty Spend by HtsNo')
    ?.reportConfig?.chartConfig ?? { chartType: ReportChartType.NO_CHART, chartName: 'No Chart' };

  const monthlyOverallLandedCostsData = chartData['Overall Customs Landed Cost'] ?? [];
  const generalDutiesBySkuData = chartData['Duties and Fees Spend By Product'] ?? [];
  const totalDutiesByCountryData = chartData['Distribution of Products by Country'] ?? [];
  const additionalFeesData = chartData['Additional Fees'] ?? [];
  const generalDutiesByHtsnoData = chartData['General Duty Spend by HtsNo'] ?? [];

  // ----- Define Columns for Filtering -----
  const [columns] = useState<ColumnConfig[]>([
    {
      name: 'category',
      displayName: 'Category',
      isVisible: true,
      sortable: true,
      filterable: true,
      dataType: 'string',
    },
    {
      name: 'date',
      displayName: 'Date',
      isVisible: true,
      sortable: true,
      filterable: true,
      dataType: ImportalTableDataType.DATE_STRING,
    },
  ]);

  // ----- Define Table Configuration -----
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    columns: columns,
    filterConfig: {},
    sortConfig: [],
    subKeyForData: '',
  });

  // ----- Define Selected Filtering Column and Values -----
  const [selectedFilteringColumn, setSelectedFilteringColumn] = useState<ColumnConfig | null>(null);
  const [selectedFilteringValues, setSelectedFilteringValues] = useState<any[]>([]);

  // ----- Add Filter Chip -----
  const handleAddFilterChip = (columnName: string, displayName: string, values: Array<any>) => {
    const newFilterConfig = {
      ...tableConfig.filterConfig,
      [columnName]: {
        allowedValues: values,
        columnName: columnName,
        displayName: displayName,
      },
    };
    setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
  };

  // ----- Delete Filter Chip -----
  const handleDeleteFilterChip = (columnName: string, value: string) => {
    const newFilterConfig = { ...tableConfig.filterConfig };

    if (value === '') {
      delete newFilterConfig[columnName];
    } else {
      const filteredValues = newFilterConfig[columnName].allowedValues.filter((val: any) => val !== value);
      if (filteredValues.length === 0) {
        delete newFilterConfig[columnName];
      } else {
        newFilterConfig[columnName] = {
          ...newFilterConfig[columnName],
          allowedValues: filteredValues,
        };
      }
    }
    setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
  };

  // ----- Manage Filtered Data for Each Chart -----
  const [filteredData, setFilteredData] = useState<{
    overallLandedCosts: any[];
    generalDuty: any[];
    totalDutiesByCountryData: any[];
    additionalFees: any[];
    generalDutyByHtsno: any[];
  }>({
    overallLandedCosts: [],
    generalDuty: [],
    totalDutiesByCountryData: [],
    additionalFees: [],
    generalDutyByHtsno: [],
  });

  useEffect(() => {
    setFilteredData({
      overallLandedCosts: monthlyOverallLandedCostsData,
      generalDuty: generalDutiesBySkuData,
      totalDutiesByCountryData: totalDutiesByCountryData,
      additionalFees: additionalFeesData,
      generalDutyByHtsno: generalDutiesByHtsnoData,
    });
  }, [chartData]);

  // ----- Local Search State for ImportalSearch -----
  const [searchQuery, setSearchQuery] = useState('');

  // Handler for ImportalSearch changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    // You can also incorporate search logic here if you want to filter data
    // setFilteredData(...) etc.
  };

  // ----- Apply Filters -----
  useEffect(() => {
    const getFilteredData = (dataArray: any[]): any[] => {
      return dataArray.filter((item) => {
        return Object.keys(tableConfig.filterConfig).every((columnName) => {
          const filter = tableConfig.filterConfig[columnName];
          if (filter.allowedValues.length === 0) return true;

          const itemValue = item[columnName];
          const columnConfig = columns.find((col) => col.name === columnName);
          if (!columnConfig) return true;

          if (filter.allowedValues.length === 2 && columnConfig.dataType === ImportalTableDataType.DATE_STRING) {
            const [startDate, endDate] = filter.allowedValues;
            const itemDate = moment(itemValue);
            return itemDate.isSameOrAfter(moment(startDate), 'day') && itemDate.isSameOrBefore(moment(endDate), 'day');
          }
          return filter.allowedValues.includes(itemValue);
        });
      });
    };

    const filteredOverallLandedCostsData = getFilteredData(monthlyOverallLandedCostsData);
    const filteredGeneralDutyData = getFilteredData(generalDutiesBySkuData);
    const filteredTotalDutiesByCountryData = getFilteredData(totalDutiesByCountryData);
    const filteredAdditionalFeesData = getFilteredData(additionalFeesData ?? []);
    const filteredGeneralDutyByHtsnoData = getFilteredData(generalDutiesByHtsnoData ?? []);

    setFilteredData({
      overallLandedCosts: filteredOverallLandedCostsData,
      generalDuty: filteredGeneralDutyData,
      totalDutiesByCountryData: filteredTotalDutiesByCountryData,
      additionalFees: filteredAdditionalFeesData,
      generalDutyByHtsno: filteredGeneralDutyByHtsnoData,
    });
  }, [
    tableConfig,
    monthlyOverallLandedCostsData,
    generalDutiesBySkuData,
    totalDutiesByCountryData,
    additionalFeesData,
    generalDutiesByHtsnoData,
    columns,
  ]);

  // ----- Render Filter Chips -----
  const renderFilterChips = () => {
    return Object.keys(tableConfig.filterConfig).map((columnName) => {
      const filterConfig = tableConfig.filterConfig[columnName];
      const columnConfig = columns.find((col) => col.name === columnName);
      const isDateColumn = columnConfig?.dataType === ImportalTableDataType.DATE_STRING;

      if (isDateColumn && filterConfig.allowedValues.length === 2) {
        const [startDate, endDate] = filterConfig.allowedValues;
        return (
          <StyledChip
            key={`${columnName}-date-range`}
            label={`${filterConfig.displayName || columnName}: ${moment(startDate).format('MM/DD/YYYY')} - ${moment(
              endDate
            ).format('MM/DD/YYYY')}`}
            onDelete={() => handleDeleteFilterChip(columnName, '')}
          />
        );
      }

      return filterConfig.allowedValues.map((value: any) => (
        <StyledChip
          key={`${columnName}-${value}`}
          label={`${filterConfig.displayName || columnName}: ${value}`}
          onDelete={() => handleDeleteFilterChip(columnName, value)}
        />
      ));
    });
  };

  // ----- Example combined data array for demonstration -----
  const sortedAndFilteredData = [
    ...filteredData.overallLandedCosts.map((item) => ({ ...item, source: 'monthlyOverallLandedCostsData' })),
    ...filteredData.generalDuty.map((item) => ({ ...item, source: 'generalDutiesBySkuData' })),
    ...filteredData.totalDutiesByCountryData.map((item) => ({ ...item, source: 'totalDutiesByCountryData' })),
    ...filteredData.additionalFees.map((item) => ({ ...item, source: 'additionalFeesData' })),
    ...filteredData.generalDutyByHtsno.map((item) => ({ ...item, source: 'generalDutyByHtsnoData' })),
  ];

  // ----- Options for Action Buttons (including "View Report") -----
  const menuOptions = [
    { label: 'Edit', onClick: () => console.log('Edit clicked') },
    { label: 'Delete', onClick: () => console.log('Delete clicked') },
    {
      label: 'View Report',
      onClick: () => navigate(`./${reportId}`), // Navigate to /reports/:reportId
    },
  ];

  return (
    <>
      {/* ----- Top Section: ImportalAutocomplete, Filter, Filter Chips, and Search ----- */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <div style={{ flex: 1, display: 'flex', gap: '6px', alignItems: 'end' }}>
          {/* Autocomplete */}
          <ImportalAutocomplete
            options={columns}
            value={selectedFilteringColumn}
            onChange={(event, value) => {
              setSelectedFilteringColumn(value as ColumnConfig | null);
              setSelectedFilteringValues([]);
            }}
            placeholder="Filter by column..."
          />

          {/* ImportalFilter */}
          <ImportalFilter
            selectedFilteringColumn={selectedFilteringColumn}
            selectedFilteringValues={selectedFilteringValues}
            setSelectedFilteringColumn={setSelectedFilteringColumn}
            setSelectedFilteringValues={setSelectedFilteringValues}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            sortedAndFilteredData={sortedAndFilteredData}
            handleAddFilterChip={handleAddFilterChip}
          />

          {/* Filter Chips */}
          <div style={{ overflowY: 'auto', alignSelf: 'center', maxHeight: '40px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'end', columnGap: '4px', rowGap: '2px' }}>
              {renderFilterChips()}
            </div>
          </div>
        </div>

        {/* Search & "Create New Report" Button */}
        <div style={{ display: 'flex', alignItems: 'end', gap: '8px' }}>
          <ImportalSearch value={searchQuery} onChange={handleSearchChange} placeholder="Search..." />

          <ImportalPrimaryButton
            style={{ width: '200px', borderRadius: '10px', fontSize: '14px' }}
            onClick={() => console.log('Create New Report')}
            text="Create New Report"
            endIcon={<Add />}
          />
        </div>
      </div>
      <div style={{ height: '100px', width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px' }}>
        {/* <div style={{ display: 'flex', width: '100%', gap: '24px', marginBottom: '24px' }}> */}
        <ImportalReportCard
          title={overallLandedCostsChartConfig.chartName}
          viewFullReport={true}
          menuOptions={menuOptions}
          // style={{ flex: 1 }}
        >
          <ImportalBarChart chartConfig={overallLandedCostsChartConfig} data={filteredData.overallLandedCosts} />
        </ImportalReportCard>
        <ImportalReportCard
          title={generalDutyChartConfig.chartName}
          viewFullReport={true}
          menuOptions={menuOptions}
          // style={{ flex: 1 }}
        >
          <ImportalStackedBarChart chartConfig={generalDutyChartConfig} data={filteredData.generalDuty} />
        </ImportalReportCard>
        {/* </div> */}

        {/* <div style={{ display: 'flex', width: '100%', gap: '24px', marginBottom: '24px' }}> */}
        <ImportalReportCard title={countryPieChartConfig.chartName} viewFullReport={true} menuOptions={menuOptions}>
          <ImportalPieChart chartConfig={countryPieChartConfig} data={totalDutiesByCountryData} />
        </ImportalReportCard>
        <ImportalReportCard
          title={generalDutyByHtsnoChartConfig.chartName}
          viewFullReport={true}
          menuOptions={menuOptions}
          // style={{ flex: 1 }}
        >
          <ImportalBarChart chartConfig={generalDutyByHtsnoChartConfig} data={filteredData.generalDutyByHtsno} />
        </ImportalReportCard>
        {/* </div> */}
        {/* <div style={{ display: 'flex', width: '100%', gap: '24px', marginBottom: '24px' }}> */}
        <ImportalReportCard
          title={additionalFeesChartConfig.chartName}
          viewFullReport={true}
          menuOptions={menuOptions}
          // style={{ width: '500px' }}
        >
          <ImportalPieChart chartConfig={additionalFeesChartConfig} data={filteredData.additionalFees} />
        </ImportalReportCard>
        {/* </div> */}
      </div>
    </>
  );
}
