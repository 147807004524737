import React from 'react';
import { errorMessageMap } from '@/utils/errorMessages';
import InfoAlert from '@/components/info-alert/InfoAlert';
import WarningAlert from './WarningAlert/WarningAlert';

const renderProductLibraryNotifications = (
  notifications,
  setCloseInfoAlert,
  setCloseWarningAlert,
  closeInfoAlert,
  closeWarningAlert,
  onWarningAlertActionClick // new prop: a function to call when the warning alert's action is clicked
) => {
  const getFormattedMessage = (errorCode, quantity) => {
    const messageTemplate = errorMessageMap[errorCode] || errorCode;

    const templateData = {
      plural: quantity > 1 ? 's' : '',
      verb: quantity > 1 ? 'are' : 'is',
      verbHasHave: quantity > 1 ? 'have' : 'has',
    };

    const message = messageTemplate
      .replace('{quantity}', quantity)
      .replace('{plural}', templateData.plural)
      .replace('{verb}', templateData.verb)
      .replace('{verbHasHave}', templateData.verbHasHave);

    return message;
  };

  // Separate notifications into warnings and others.
  const warningNotifications = notifications.filter(
    (notification) => notification.errorCode === 'FEB_2025_CHINA_TARIFF'
  );
  const infoNotifications = notifications.filter((notification) => notification.errorCode !== 'FEB_2025_CHINA_TARIFF');

  // Combine messages for info alerts if there are multiple.
  const infoMessage = infoNotifications
    .map((notification) => getFormattedMessage(notification.errorCode, notification.total))
    .join(', ');

  return (
    <div>
      {/* Render WarningAlerts only if the warning alert is not dismissed */}
      {warningNotifications.length > 0 &&
        closeWarningAlert &&
        warningNotifications.map((notification, index) => (
          <WarningAlert
            key={`warning-${index}`}
            message={`${notification.total} products are subject to the Trump administration's recently announced Additional 10% China Tariff`}
            onClose={() => setCloseWarningAlert(false)}
            actionText="See which ones"
            onActionClick={() => onWarningAlertActionClick(notification)}
          />
        ))}

      {/* Render a single InfoAlert only if it has not been dismissed */}
      {infoNotifications.length > 0 && closeInfoAlert && (
        <InfoAlert message={infoMessage} onClose={() => setCloseInfoAlert(false)} />
      )}
    </div>
  );
};

export default renderProductLibraryNotifications;
