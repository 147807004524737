import { renderChartLabel } from '@/utils/reportingUtils';
import { ChartUnitType } from 'common/interfaces/reports';
import * as d3 from 'd3';
import { useState } from 'react';

interface ImportalHorizontalBarsProps {
  data: any[];
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleBand<string>;
  seriesNameKey: string;
  seriesValueKey: string;
  barColor?: string;
  rounded?: boolean;
  unit: ChartUnitType;
}

export function ImportalHorizontalBars({
  data,
  xScale,
  yScale,
  seriesNameKey,
  seriesValueKey,
  barColor = '#FFC107', // Default bar color
  rounded = false,
  unit,
}: ImportalHorizontalBarsProps) {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  return (
    <g>
      {data.map((d: any, i: number) => {
        const category = d[seriesNameKey];
        const value = d?.[0] !== undefined && d?.[1] !== undefined ? d[1] - d[0] : +d[seriesValueKey];
        const barY = yScale(category) ?? 0;
        const barX = xScale(d[0] ?? 0);
        const barWidth = xScale(d[1] ?? value) - xScale(d[0] ?? 0);

        return (
          <g key={i} onMouseEnter={() => setHoveredIndex(i)} onMouseLeave={() => setHoveredIndex(null)}>
            {/* The actual bar */}
            <rect x={barX} y={barY} width={barWidth} height={yScale.bandwidth()} fill={barColor} rx={rounded ? 8 : 0} />

            {hoveredIndex === i && (
              <g
                transform={`
      translate(
        ${barWidth + 8}, 
        ${barY + yScale.bandwidth() / 2}
      )
    `}
              >
                <rect x={0} y={-12} width={70} height={25} rx={4} fill="#666" />
                <text
                  x={35} // half of 70
                  y={0}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize="12px"
                  fill="white"
                >
                  {renderChartLabel(value, unit)}
                </text>
              </g>
            )}
          </g>
        );
      })}
    </g>
  );
}
