import { useChartDimensions } from '@/custom-hooks/reporting/useChartDimensions';
import { renderChartLabel } from '@/utils/reportingUtils';
import { ChartConfig, ChartUnitType, LegendPositionType } from 'common/interfaces/reports';
import * as d3 from 'd3';
import { useMemo } from 'react';
import { ImportalChartLegend } from './ImportalChartLegend';

export interface ImportalPieChartProps {
  chartConfig: ChartConfig;
  data: Array<{
    [key: string]: any;
    color?: string;
  }>;
}

const chartSettings = {
  marginLeft: 100,
  marginRight: 40,
  marginTop: 20,
  marginBottom: 60,
};

// Custom greens palette (darkest to lightest)
const defaultGreens = ['#388E3C', '#4CB150', '#84C889', '#AFDCB2', '##D3EFD5'];

export default function ImportalPieChart({ chartConfig, data }: ImportalPieChartProps) {
  const [ref, dms] = useChartDimensions(chartSettings);

  const { seriesNameKey, seriesValueKey } = chartConfig.pieConfig ?? {};

  /**
   * Sort data in descending order (largest slice first).
   * If you do NOT want to reorder the data for the chart layout,
   * you can omit this step or adjust your logic accordingly.
   */
  const sortedData = useMemo(() => {
    if (!data || !data.length || !seriesValueKey) return [];
    return [...data].sort((a, b) => b[seriesValueKey] - a[seriesValueKey]);
  }, [data, seriesValueKey]);

  /**
   * Legend keys should match sortedData order,
   * so the legend lines up with the largest slice first, etc.
   */
  const legendKeys = useMemo(() => {
    if (!sortedData.length || !seriesNameKey) return [];
    return sortedData.map((s) => s[seriesNameKey]);
  }, [sortedData, seriesNameKey]);

  /**
   * Use custom greens as the default color range.
   * Domain is also derived from sortedData, so the 1st index
   * (largest slice) gets the 1st color (darkest green).
   */
  const colorScale = useMemo(() => {
    if (!sortedData.length || !seriesNameKey) return null;
    return d3
      .scaleOrdinal<string>()
      .domain(sortedData.map((d) => d[seriesNameKey]))
      .range(defaultGreens)
      .unknown('#ccc');
  }, [sortedData, seriesNameKey]);

  /**
   * Generate the pie arcs from sortedData.
   * By default, d3.pie() will reorder slices by value, so we override with .sort(null)
   * to preserve the array order we already imposed with sortedData.
   */
  const arcs = useMemo(() => {
    if (!sortedData.length || !seriesValueKey) return [];
    const pie = d3
      .pie<{ [key: string]: any }>()
      .sort(null)
      .value((d) => +d[seriesValueKey]);
    return pie(sortedData);
  }, [sortedData, seriesValueKey]);

  const width = dms.width;
  const height = dms.height;
  const outerR = Math.min(width, height) / 3;

  const arcGen = useMemo(() => {
    return d3.arc<d3.PieArcDatum<{ [key: string]: any }>>().innerRadius(0).outerRadius(outerR);
  }, [outerR]);

  const labelArcGen = useMemo(() => {
    return d3
      .arc<d3.PieArcDatum<{ [key: string]: any }>>()
      .innerRadius(outerR * 0.5)
      .outerRadius(outerR * 0.5);
  }, [outerR]);

  const isColumnLayout =
    chartConfig.legendPosition === LegendPositionType.TOP || chartConfig.legendPosition === LegendPositionType.BOTTOM;

  return (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
      <figure style={{ margin: 0, width: dms.width, height: dms.height }}>
        {/* {chartConfig.legendPosition === LegendPositionType.TOP && ( */}
        <ImportalChartLegend keys={legendKeys} colorScale={colorScale} legendPosition={LegendPositionType.TOP} />
        {/* )} */}
        <svg viewBox={`0 0 ${dms.width} ${dms.height}`} preserveAspectRatio="xMidYMid meet">
          <title id="pie-title">{chartConfig.chartName}</title>
          <rect fill="#fff" />
          <g transform={`translate(${dms.width / 2}, ${dms.height / 2})`}>
            <g stroke="none">
              {arcs.map((arcData, i) => (
                <path
                  key={`arc-${i}`}
                  fill={
                    // Use the datum's color if given, otherwise use the scale
                    arcData.data.color ? arcData.data.color : colorScale?.(arcData.data[seriesNameKey as string])
                  }
                  d={arcGen(arcData) ?? undefined}
                >
                  <title>
                    {`${arcData.data[seriesNameKey as string]}: ${renderChartLabel(
                      arcData.data[seriesValueKey as string],
                      chartConfig.pieConfig?.seriesValueUnits ?? ChartUnitType.Number
                    )}`}
                  </title>
                </path>
              ))}
            </g>

            {chartConfig.showLabelInChart && (
              <g>
                {arcs.map((arcData, i) => {
                  const [labelX, labelY] = labelArcGen.centroid(arcData);
                  return (
                    <text
                      key={`label-${i}`}
                      transform={`translate(${labelX}, ${labelY})`}
                      fill="#fff"
                      fontWeight="bold"
                      textAnchor="middle"
                      style={{ pointerEvents: 'none', fontSize: '3px' }}
                    >
                      {renderChartLabel(
                        arcData.data[seriesValueKey as string],
                        chartConfig.pieConfig?.seriesValueUnits ?? ChartUnitType.Number
                      )}
                    </text>
                  );
                })}
              </g>
            )}
          </g>
        </svg>
      </figure>

      {chartConfig.legendPosition === LegendPositionType.BOTTOM && (
        <ImportalChartLegend keys={legendKeys} colorScale={colorScale} legendPosition={chartConfig.legendPosition} />
      )}
      {chartConfig.legendPosition === LegendPositionType.LEFT && (
        <ImportalChartLegend
          keys={legendKeys}
          colorScale={colorScale}
          legendPosition={chartConfig.legendPosition}
          styles={{ marginRight: '24px' }}
        />
      )}
      {chartConfig.legendPosition === LegendPositionType.RIGHT && (
        <ImportalChartLegend
          keys={legendKeys}
          colorScale={colorScale}
          legendPosition={chartConfig.legendPosition}
          styles={{ marginLeft: '24px' }}
        />
      )}
    </div>
  );
}
