import React, { useMemo } from 'react';
import { ChartConfig, ChartUnitType } from 'common/interfaces/reports';
import { useChartDimensions } from '@/custom-hooks/reporting/useChartDimensions';
import { CategoricalXAxis } from '@/components/reporting/CategoricalXAxis';
import { NumericYAxis } from '@/components/reporting/NumericYAxis';
import { ImportalHistogramBars } from '@/components/reporting/ImportalHistogramBars';

export interface Props {
  chartConfig: ChartConfig;
  data: any[];
}

const chartSettings = {
  marginLeft: 11,
  marginTop: 50,
};

export default function ImportalHistogram({ chartConfig, data }: Props) {
  const [ref, dms] = useChartDimensions(chartSettings);

  const chartLayout = {
    yAxisAreaWidth: 80,
    xAxisAreaHeight: 80,
  };

  // Convert raw data to {category, value}
  const seriesData = useMemo(() => {
    if (!chartConfig?.histogramConfig?.seriesNameKey) return [];
    if (!chartConfig?.histogramConfig?.seriesValueKey) return [];
    const { seriesNameKey, seriesValueKey } = chartConfig.histogramConfig;
    return data.map((datum) => ({
      category: datum[seriesNameKey],
      value: +datum[seriesValueKey],
    }));
  }, [chartConfig, data]);

  // All category names
  const categories: string[] = useMemo(() => {
    if (!chartConfig?.histogramConfig?.seriesNameKey) return [];
    const key = chartConfig.histogramConfig.seriesNameKey;
    return data.map((d) => d[key]);
  }, [chartConfig, data]);

  /**
   * Strongly type `range` as a *two-element tuple*: [min, max].
   * Make sure you actually return exactly 2 elements.
   */
  const range: [number, number] = useMemo(() => {
    if (!chartConfig?.histogramConfig?.seriesValueKey) {
      return [0, 0];
    }
    const key = chartConfig.histogramConfig.seriesValueKey;
    // Convert values to number
    const values = data.map((d) => +d[key]);
    // Fallback if data is empty, or use Math.max if not
    const maxVal = values.length ? Math.max(...values) : 0;
    return [0, maxVal];
  }, [chartConfig, data]);

  return (
    <div ref={ref}>
      <svg width={'100%'} height={300}>
        {/* Y-axis area */}
        <g transform={`translate(${dms.marginLeft + chartLayout.yAxisAreaWidth}, ${dms.marginTop})`}>
          <NumericYAxis
            axisLabel={chartConfig.histogramConfig?.yAxisName || 'Y Axis'}
            height={dms.boundedHeight - chartLayout.xAxisAreaHeight}
            range={range} // <-- Now a 2-element tuple
            tickValues={chartConfig.yAxisTickValues} // if you have ticks to pass
          />
        </g>

        <g transform={`translate(${dms.marginLeft}, ${dms.marginTop})`}>
          {/* Bars group */}
          <g transform={`translate(${chartLayout.yAxisAreaWidth}, 0)`}>
            <ImportalHistogramBars
              width={dms.boundedWidth - chartLayout.yAxisAreaWidth}
              height={dms.boundedHeight - chartLayout.xAxisAreaHeight}
              categories={seriesData}
              range={range}
              unit={chartConfig.histogramConfig?.yAxisUnits || ChartUnitType.Number}
              customGridLines={chartConfig.yAxisTickValues}
            />
          </g>

          {/* X-axis area */}
          <g transform={`translate(${chartLayout.yAxisAreaWidth}, ${dms.boundedHeight - chartLayout.xAxisAreaHeight})`}>
            <CategoricalXAxis
              axisLabel={chartConfig.histogramConfig?.xAxisName || 'X Axis'}
              categories={categories}
              width={dms.boundedWidth - chartLayout.yAxisAreaWidth}
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
