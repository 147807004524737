import { useAPI } from '@/api/APIContext';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import ImportalPage from '@/shared-components/ImportalPage/ImportalPage';
import { Report } from 'common/interfaces/reports';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ReportDefault from './ReportDefault';

export default function ReportPage() {
  const { reportId } = useParams<{ reportId: string }>();
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [report, setReport] = useState<Report>();
  const location = useLocation();
  const api = useAPI();
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const reportId = pathParts[pathParts.length - 1];
    if (!reportId) return;
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getReport(reportId)
      .then(({ data }) => {
        setReport(data);
        setLoadingStatus(LoadingStatus.SUCCESS);
      })
      .catch((err) => {
        console.error('error getting report');
        console.error(err);
        setLoadingStatus(LoadingStatus.ERROR);
      });

    return () => {
      document.title = 'Importal';
    };
  }, [api, report, location]);

  return (
    <ImportalPage
      loadingStatus={loadingStatus}
      loadingText={loadingText}
      successText={successText}
      errorText={errorText}
      header="General Duty Spend by HTS Code"
      subheader="View the corresponding charts and tables in further detail for your report"
    >
      {report && <ReportDefault report={report} onAdvancedClicked={() => {}} />}
    </ImportalPage>
  );
}
